export const getUser = () => {
    if (typeof document !== "undefined") {
    // Get user from cookie
        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) {
                return parts
                    .pop()
                    .split(";")
                    .shift();
            }
        };

        const getUser = unescape(getCookie("ajs_user_id")).replace(/"/g, "");

        if (getUser === "null") {
            return false;
        }

        return getUser;
    }
};
