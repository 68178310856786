import React, { createContext, useEffect } from "react";

import { useToggle } from "@hooks/use-toggle";

const MenuContext = createContext();

const MenuProvider = ({ children }) => {
    const { isOpen, toggle } = useToggle();
    const state = { isOpen, toggle };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("keydown", onKeyDown);
        }

        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [isOpen]);

    const onKeyDown = ({ keyCode }) => {
        if (keyCode === 27) {
            toggle();

            const bodyClass = document.body.classList;
            bodyClass.contains("menu-open") && bodyClass.remove("menu-open");
        }
    };

    return <MenuContext.Provider value={state}>{children}</MenuContext.Provider>;
};

export { MenuProvider };
export const MenuConsumer = MenuContext.Consumer;
