import React, { createContext, useState, useEffect } from "react";

import { getUser } from "@utils";

const UserContext = createContext();
const userId = getUser();

const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        setUser(userId);
    }, []);

    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export { UserProvider };
export const UserConsumer = UserContext.Consumer;
