import React from "react";

import { UserProvider } from "@contexts/UserContext";
import { MenuProvider } from "@contexts/MenuContext";

export default ({ element }) => (
    <UserProvider>
        <MenuProvider>{element}</MenuProvider>
    </UserProvider>
);
